import AppsIcon from '@mui/icons-material/Apps';
import BusinessIcon from '@mui/icons-material/Business';
import StoreIcon from '@mui/icons-material/Store';
import LocalMall from '@mui/icons-material/LocalMall';
import { DashboardLayout } from '../components/Layouts/dashboardLayout';
import { RequireAuth } from '../components/requireAuth';
import { IndexPage, LoginPage, VenuesPage, VenuePage, CompaniesPage, CompanyPage } from '../pages';

export type IRouteName = 'home' | 'login' | 'venues' | 'venue' | 'restaurants' | 'companies' | 'company';

interface IRoute {
  name: IRouteName;
  path: string;
  icon: JSX.Element | null;
  element: JSX.Element | null;
  label?: string;
  isPrivate?: boolean;
  isNested?: boolean;
}

/**
 * sets a route to require our authentication method to access to it
 * @param element
 * @returns
 */
const setPrivateRoute = (element: JSX.Element) => (
  <RequireAuth>{element}</RequireAuth>
);

const setDashboardLayout = (element: JSX.Element) => (
  <DashboardLayout>{element}</DashboardLayout>
);

const routes: IRoute[] = [
  {
    // make sure login is always the first one
    name: 'login',
    path: '/login',
    icon: null,
    element: <LoginPage />,
  },
  {
    name: 'home',
    label: 'Home',
    path: '/',
    icon: <AppsIcon />,
    isPrivate: true,
    element: setPrivateRoute(setDashboardLayout(<IndexPage />)),
  },
  {
    name: 'venues',
    label: 'Venues',
    path: '/venues',
    icon: <LocalMall />,
    isPrivate: true,
    element: setPrivateRoute(setDashboardLayout(<VenuesPage />)),
  },
  {
    name: 'venue',
    path: '/venues/:slug',
    icon: null,
    element: setPrivateRoute(setDashboardLayout(<VenuePage />)),
  },
  {
    name: 'companies',
    label: 'Companies',
    path: '/companies',
    icon: <BusinessIcon />,
    isPrivate: true,
    element: setPrivateRoute(setDashboardLayout(<CompaniesPage />)),
  },
  {
    name: 'company',
    path: '/companies/:slug',
    icon: null,
    element: setPrivateRoute(setDashboardLayout(<CompanyPage />)),
  },
  {
    name: 'restaurants',
    label: 'Restaurants',
    path: '/restaurants',
    icon: <StoreIcon />,
    isPrivate: true,
    element: null,
  },
];

export const getAllRoutes = (): IRoute[] => {
  return routes;
};

export const getRoute = (route: IRouteName): IRoute => {
  const r = routes.find((r) => r.name === route) || routes[0];
  return r;
};
