import { useMutation } from 'react-query';
import { getEndpoint } from '../../utils/getEndpoint';
import { request } from '../../utils/axios';
import { IRQRequest } from '../../types';

interface IRQNewCompanyRequest extends IRQRequest {
  name: string;
  company: string;
  venue: string | null;
}

interface IRestaurantResponse {
  random_slug: string;
  identifier: string;
  name: string;
  venue: string;
  company: string;
}

/**
 * Hook to create a new restaurant
 */
export const useCreateRestaurant = ({
  options = {},
  name,
  company,
  venue,
}: IRQNewCompanyRequest) => {
  return useMutation<IRestaurantResponse, Error>(
    () =>
      request({
        method: 'POST',
        data: {
          name,
          company,
          venue,
        },
        url: getEndpoint('staff/Restaurant', true),
      }),
    options
  );
};
