import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid, Tabs, Tab } from '@mui/material';
import { LoadingPage } from '../';
import { useGetCompany } from '../../hooks/company/get';
import { TabPanel } from '../../atoms/TabPanel';
// import GeneralTab from './general';


export const VenuePage = () => {
  const { slug } = useParams();
  const [tab, setTab] = useState<number>(0);
  const { data, isLoading } = useGetCompany({ slug });

  if (isLoading) return <LoadingPage />;

  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography variant='h5' gutterBottom>
        {data?.name}
      </Typography>
      <Grid
        sx={{
          marginBottom: 2,
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
          <Tabs value={tab} onChange={(event, newTab) => setTab(newTab)}>
            <Tab label='General' />
            <Tab label='Menu' />
            <Tab label='Settings' />
          </Tabs>
        </Box>
        <TabPanel tab={tab} index={0}>
          <></>
          {/* <GeneralTab /> */}
        </TabPanel>
        <TabPanel tab={tab} index={1}>
          <></>
        </TabPanel>
      </Grid>
    </Box>
  );
};
