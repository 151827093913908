/**
 * React Query Keys
 */

export const userKeys = {
  user: ['user'] as const,
  venues: ['venues'] as const,
  companies: ['companies'] as const,
  company: (companyId: string) => [...userKeys.companies, companyId] as const,
  companyRestaurants: (companyId: string) =>
    [...userKeys.companies, companyId, 'restaurants'] as const,
  token: () => [...userKeys.user, 'authToken'] as const,
};
