import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: 'Ubuntu'

  },
  palette: {
    primary: {
      light: '#ff4b75',
      main: '#fd003b',
      dark: '#b10029',
    },
    secondary: {
      light: '#84A9FF',
      main: '#3366FF',
      dark: '#1939B7',
    },
    background: {
      default: '#EFF0F3',
      paper: '#FBFCFC',
    },
    grey: {
      '800': '#212B36',
      '900': '#161C24',
    },
  },
});

export default theme;
