import React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../assets/logo2x.jpg';

interface IProps {
  drawerWidth: number;
  handleDrawerToggle: () => void;
}

export function NavigationAppBar({ drawerWidth, handleDrawerToggle }: IProps) {
  return (
    <AppBar
      position='fixed'
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
        backgroundColor: (theme) => theme.palette.background.paper,
        color: (theme) => theme.palette.grey[800],
      }}
      elevation={1}
    >
      <Toolbar>
        <IconButton
          size='large'
          edge='start'
          color='inherit'
          aria-label='menu'
          sx={{ mr: 2, display: { sm: 'block', md: 'none' } }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Box
            component='img'
            sx={{
              height: 50,
              width: 95,
            }}
            src={Logo}
          />
        </Box>
        <Button color='inherit'>Admin</Button>
      </Toolbar>
    </AppBar>
  );
}
