import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import RestaurantsDatatable from '../../components/Datatables/restaurants';
import { CreateRestaurantModal } from '../../components/Modals/CreateRestaurantModal';

export const RestaurantsTab = (props: any) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpenModal = (open: boolean) => {
    setOpenModal(open);
  };

  return (
    <>
      <Button
        variant='contained'
        startIcon={<AddIcon />}
        onClick={() => handleOpenModal(true)}
        sx={{ marginBottom: 2 }}
      >
        Create New Restaurant
      </Button>

      <RestaurantsDatatable data={[]} />
      <CreateRestaurantModal open={openModal} handleOpen={handleOpenModal} />
    </>
  );
};

export default RestaurantsTab;
