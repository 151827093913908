import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';

import { getAllRoutes } from '../../../routes/routes';

export const DrawerData = () => {
  const routes = getAllRoutes();

  return (
    <>
      <Toolbar />
      <List>
        {routes.map((r) => {
          const { path, icon, name, label, isPrivate } = r;
          if (!isPrivate) return null;
          return (
            <ListItem key={name} button component={Link} to={path}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
