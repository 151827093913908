import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Box, Grid, Typography } from '@mui/material';
import { useGetAllCompanies } from '../hooks';
import { LoadingPage } from '.';
import CompaniesDatatable from '../components/Datatables/companies';
import { CreateCompanyModal } from '../components/Modals/CreateCompanyModal';

export const CompaniesPage = (props: any) => {
  const [openCreateVenue, setOpenCreateVenue] = useState<boolean>(false);

  const { data: companies, isLoading } = useGetAllCompanies({});

  const handleOpenModal = (open: boolean) => {
    setOpenCreateVenue(open);
  };

  if (isLoading) return <LoadingPage />;

  return (
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography variant='h5' gutterBottom>
        {'Companies'}
      </Typography>
      <Grid
        sx={{
          marginBottom: 2,
        }}
      >
        <Button
          variant='contained'
          startIcon={<AddIcon />}
          onClick={() => handleOpenModal(true)}
        >
          Create New Company
        </Button>
      </Grid>
      <CompaniesDatatable data={companies?.results || []} />
      <CreateCompanyModal open={openCreateVenue} handleOpen={handleOpenModal} />
    </Box>
  );
};
