const BACKEND = 'https://tableswift.comuna18.com/api';
const VERSION = '/v1';

type IEndpoint =
  | 'login'
  | 'register'
  | 'company'
  | 'venue'
  | 'restaurant'
  | 'admin'
  | 'token'
  | 'token/refresh'
  | 'staff/Venue'
  | 'staff/Company'
  | string;

export const getEndpoint = (
  endpoint: IEndpoint,
  useVersion: boolean = false,
  query: string = ''
) => {
  // if (process.env.NODE_ENV === 'development') {
  //   return `/${endpoint}${query ? `?${query}` : ''}`;
  // }

  return `${BACKEND}${useVersion ? VERSION : ''}/${endpoint}/${
    query ? `?${query}` : ''
  }`;
};
