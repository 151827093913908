
interface IProps {
  tab: number;
  index: number;
  children: JSX.Element;
}

export const TabPanel = (props: IProps) => {
  if (props.tab !== props.index) return null;

  return props.children;
};