import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Box, Grid, Typography } from '@mui/material';
import { useGetAllVenues } from '../hooks';
import { LoadingPage } from '.';
import VenueDatatable from '../components/Datatables/venues';
import { CreateVenueModal } from '../components/Modals/createVenueModal';

export const VenuesPage = (props: any) => {
  const [openCreateVenue, setOpenCreateVenue] = useState<boolean>(false);

  const {
    data: venues,
    isLoading,
    // error,
  } = useGetAllVenues({});

  const handleOpenModal = (open: boolean) => {
    setOpenCreateVenue(open);
  };

  if (isLoading) return <LoadingPage />;

  return (
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant='h5' gutterBottom>
        {'Venues'}
      </Typography>
      <Grid
        sx={{
          marginBottom: 2,
        }}
      >
        <Button
          variant='contained'
          startIcon={<AddIcon />}
          onClick={() => handleOpenModal(true)}
        >
          Create New Venue
        </Button>
      </Grid>
      <VenueDatatable data={venues?.results || []} />
      <CreateVenueModal open={openCreateVenue} handleOpen={handleOpenModal} />
    </Box>
  );
};
