import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRoute } from '../routes/routes';
import { useRequestRefreshToken } from '../hooks';
import { LoadingPage } from '../pages';
import { setAuthToken } from '../utils/axios';
interface IProps {
  children: JSX.Element;
}

function RequireAuth({ children }: IProps) {
  const navigate = useNavigate();
  const refreshToken = localStorage.getItem('refresh-token');
  const {
    data: authData,
    error,
    isLoading,
  } = useRequestRefreshToken({
    token: refreshToken || '',
  });

  useEffect(() => {
    if (authData) {
      localStorage.setItem('auth-token', authData.access);
      localStorage.setItem('refresh-token', authData.refresh);
      setAuthToken(authData.access);
    }
  }, [authData]);

  if (isLoading) return <LoadingPage />;

  if (error) {
    navigate(getRoute('login').path);
  }

  return children;
}

export { RequireAuth };
