import { useMutation } from 'react-query';
import { getEndpoint } from '../../utils/getEndpoint';
import { request } from '../../utils/axios';
import { IRQRequest } from '../../types';

interface IRQNewCompanyRequest extends IRQRequest {
  name: string;
}

interface ICompanyResponse {
  random_slug: string;
  identifier: string;
  create_timestamp: string;
  update_timestamp: string;
  name: string;
}

/**
 * Hook to create a new company
 * @returns {ICompanyResponse}
 */
export const useCreateCompany = ({
  options = {},
  name,
}: IRQNewCompanyRequest) => {
  return useMutation<ICompanyResponse, Error>(
    () =>
      request({
        method: 'POST',
        data: {
          name,
        },
        url: getEndpoint('staff/Company', true),
      }),
    options
  );
};
