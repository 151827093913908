import { useQuery } from 'react-query';
import { userKeys } from '../../utils/rqKeys';
import { getEndpoint } from '../../utils/getEndpoint';
import { request } from '../../utils/axios';
import { IRQRequest } from '../../types';

interface IRQVenues extends IRQRequest {
  slug?: string;
}

export interface ICompanyResponse {
  random_slug: string;
  identifier: string;
  create_timestamp: string;
  update_timestamp: string;
  name: string;
}

/**
 * Hook to get the list of all the venues the user has access to see
 * @returns {ICompany}
 */
export const useGetCompany = ({ options = {}, slug = '' }: IRQVenues) => {
  return useQuery<ICompanyResponse, Error>(
    userKeys.company(slug),
    () =>
      request({
        method: 'GET',
        url: getEndpoint(`staff/Company/${slug}`, true),
      }),
    options
  );
};
