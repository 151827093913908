import React from 'react';
import { Link } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';

interface IRow {
  name: string;
  random_slug: string;
  identifier: string;
}

interface IProps {
  data: IRow[];
}

const columns: MUIDataTableColumnDef[] = [
  {
    name: 'name',
    label: 'Restaurant',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Link component={LinkRouter} to={tableMeta.rowData[2]}>
            {value}
          </Link>
        );
      },
    },
  },
  {
    name: 'name',
    label: 'Venue',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Link component={LinkRouter} to={tableMeta.rowData[2]}>
            {value}
          </Link>
        );
      },
    },
  },
];

const Datatable = (props: IProps) => (
  <MUIDataTable title={'Restaurants'} data={props.data} columns={columns} />
);

export default Datatable;
