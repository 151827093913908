import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import { DropResult } from 'react-beautiful-dnd';
import DraggableList from './DraggableList/DraggableList';
import pick from '@cahil/utils/accessors/pick';
import { reorder } from '../../utils/reorder';
import { Item } from './types';

const List: Array<Item> = [
  {
    id: '1',
    primary: 'Orange Chicken',
    secondary: '$19.55',
  },
  {
    id: '2',
    primary: 'Teriyaki Chicken',
    secondary: '$12.15',
  },
  {
    id: '3',
    primary: 'Fried Rice',
    secondary: '$5.99',
  },
  {
    id: '4',
    primary: 'Wong Tong Soup',
    secondary: '$10.11',
  },
];

const useStyles = makeStyles({
  flexPaper: {
    flex: 1,
    minWidth: 350,
    minHeight: 500,
  },
});

export const MenuList = () => {
  const classes = useStyles();
  const [items, setItems] = React.useState(List);

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };

  return (
    <>
      <Paper className={classes.flexPaper}>
        <DraggableList items={items} onDragEnd={onDragEnd} />
      </Paper>
      {/* <Paper className={classes.flexPaper}>
        <pre>
          {JSON.stringify(
            items.map((item) => pick(item, 'id', 'primary')),
            null,
            2
          )}
        </pre>
      </Paper> */}
    </>
  );
};

export default MenuList;
