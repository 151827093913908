import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { Box, Grid, TextField, Typography } from '@mui/material';
import TableSwiftRevenue from '../components/Charts/tsRevenue';
import RestaurantQR from '../assets/restaurant.png';
import LogoSymbol from '../assets/logo_symbol.jpg';
import VenueQR from '../assets/venue.png';
import { QRCodeCanvas } from 'qrcode.react';

export const IndexPage = (props: any) => {
  const [url, setUrl] = useState<string>('/restaurantes/P12Y3S');

  return (
    <Box
      sx={{
        padding: 1,
        flexDirection: 'column',
      }}
    >
      <Paper
        sx={{
          flex: 1,
          height: '500px',
          marginBottom: 2,
        }}
      >
        <Box
          sx={{
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Typography variant='h5' gutterBottom>
            {`Revenue`}
          </Typography>
          <TableSwiftRevenue />
        </Box>
      </Paper>
      <Grid
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          width: '100%',
          height: '330px',
        }}
      >
        <Paper
          sx={{
            padding: 2,
            flex: 1,
            margin: 1,
          }}
        >
          <Typography variant='h5'>Restaurant</Typography>
          <Box
            component='img'
            sx={{
              height: 250,
              width: 250,
            }}
            src={RestaurantQR}
          />
        </Paper>
        <Paper
          sx={{
            padding: 2,
            flex: 1,
            margin: 1,
          }}
        >
          <Typography variant='h5'>Venue</Typography>
          <Box
            component='img'
            sx={{
              height: 250,
              width: 250,
            }}
            src={VenueQR}
          />
        </Paper>
        <Paper
          sx={{
            padding: 2,
            flex: 1,
            margin: 1,
          }}
        >
          <TextField
            id='standard-basic'
            label='URL Generator'
            variant='standard'
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            sx={{
              width: '100%',
              margin: 1,
            }}
          />
          <QRCodeCanvas
            size={225}
            value={`https://tableswift.com/#${url}`}
            level='H'
            includeMargin={false}
            imageSettings={{
              src: LogoSymbol,
              height: 70,
              width: 70,
              excavate: true,
            }}
          />
        </Paper>
      </Grid>
    </Box>
  );
};
