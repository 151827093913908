import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { getAllRoutes } from './routes';

const routes = getAllRoutes();

function MyRoutes() {
  return (
    <Routes>
      {routes.map((r) => {
        const { element, path, name } = r;
        return <Route key={name} path={path} element={element} />;
      })}
    </Routes>
  );
}

export { MyRoutes };
