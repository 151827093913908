import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const data = [
  {
    date: '2021-01',
    'Av. Norte': 4000,
    'Av. South': 2400,
    amt: 2400,
  },
  {
    date: '2021-02',
    'Av. Norte': 3000,
    'Av. South': 1398,
    amt: 2210,
  },
  {
    date: '2021-03',
    'Av. Norte': 2000,
    'Av. South': 9800,
    amt: 2290,
  },
  {
    date: '2021-04',
    'Av. Norte': 2780,
    'Av. South': 3908,
    amt: 2000,
  },
  {
    date: '2021-05',
    'Av. Norte': 1890,
    'Av. South': 4800,
    amt: 2181,
  },
  {
    date: '2021-06',
    'Av. Norte': 2390,
    'Av. South': 3800,
    amt: 2500,
  },
  {
    date: '2021-07',
    'Av. Norte': 3490,
    'Av. South': 4300,
    amt: 2100,
  },
  {
    date: '2000-08',
    'Av. Norte': 4000,
    'Av. South': 2400,
    amt: 2400,
  },
  {
    date: '2021-09',
    'Av. Norte': 3000,
    'Av. South': 1398,
    amt: 2210,
  },
  {
    date: '2000-10',
    'Av. Norte': 2000,
    'Av. South': 9800,
    amt: 2290,
  },
  {
    date: '2021-11',
    'Av. Norte': 2780,
    'Av. South': 3908,
    amt: 2000,
  },
  {
    date: '2021-12',
    'Av. Norte': 1890,
    'Av. South': 4800,
    amt: 2181,
  },
];

const monthTickFormatter = (tick: number) => {
  const date = new Date(tick);

  return `${date.getMonth() + 1}`;
};

const renderQuarterTick = (tickProps: any) => {
  const { x, y, payload } = tickProps;
  const { value, offset } = payload;
  const date = new Date(value);
  const month = date.getMonth();
  const quarterNo = Math.floor(month / 3) + 1;
  // const isMidMonth = month % 3 === 1;

  if (month % 3 === 1) {
    return <text x={x} y={y - 4} textAnchor='middle'>{`Q${quarterNo}`}</text>;
  }

  const isLast = month === 11;

  if (month % 3 === 0 || isLast) {
    const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;

    return <path d={`M${pathX},${y - 4}v${-35}`} stroke='red' />;
  }

  return <text x={x} y={y - 4} textAnchor='middle'>{`Q${quarterNo}`}</text>;
};

export const RevenueChart = (props: any) => {
  return (
    <ResponsiveContainer>
      <BarChart
        width={500}
        height={200}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='date' tickFormatter={monthTickFormatter} />
        <XAxis
          dataKey='date'
          axisLine={false}
          tickLine={false}
          interval={0}
          tick={renderQuarterTick}
          height={1}
          scale='band'
          xAxisId='quarter'
        />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='Av. Norte' fill='#D328BB' />
        <Bar dataKey='Av. South' fill='#0075F9' />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RevenueChart;
