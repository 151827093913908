import { useQuery } from 'react-query';
import { userKeys } from '../../utils/rqKeys';
import { getEndpoint } from '../../utils/getEndpoint';
import { request } from '../../utils/axios';
import { IRQRequest } from '../../types';

interface IRQVenues extends IRQRequest {}

interface ICompany {
  random_slug: string;
  identifier: string;
  create_timestamp: string;
  update_timestamp: string;
  name: string;
}

interface ICompaniesResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: ICompany[];
}

/**
 * Hook to get the list of all the venues the user has access to see
 * @returns {IVenueResponse}
 */
export const useGetAllCompanies = ({ options = {} }: IRQVenues) => {
  return useQuery<ICompaniesResponse, Error>(
    userKeys.companies,
    () =>
      request({
        method: 'GET',
        url: getEndpoint('staff/Company', true),
      }),
    options
  );
};
