import React, { useState, useCallback } from 'react';
import { Box } from '@mui/material';
import { NavigationAppBar } from '../AppBar';
import {
  NavigationDrawer,
  DrawerData as NavigationDrawerData,
} from '../Drawers/NavigationDrawer';

export const DashboardLayout = (props: any) => {
  const drawerWidth = 240;
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const handleDrawerToogle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  return (
    <Box sx={{ display: 'flex' }}>
      <NavigationAppBar
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToogle}
      />
      <NavigationDrawer
        drawer={NavigationDrawerData()}
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToogle}
        mobileOpen={mobileOpen}
      />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          height: 'calc(100vh - 64px)',
          marginTop: '64px',
          padding: 1,
          overflow: 'auto',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
