import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';

export const LoadingPage = () => {
  return (
    <Grid container justifyContent={'center'} alignItems={'center'} sx={{height: '100%'}}>
      <CircularProgress
        color='primary'
        thickness={5}
        size={100}
        disableShrink
      />
    </Grid>
  );
};
