import React, { FormEvent, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useRequestLogin } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { getRoute } from '../routes/routes';
import Logo from '../assets/logo2x.jpg';

export const LoginPage = (props: any) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const {
    refetch: requestLogin,
    data: authToken,
    // error, // TODO: LOW: use it to render an unauthenticated error
  } = useRequestLogin({
    data: { username, password },
    options: { enabled: false },
  });

  useEffect(() => {
    if (authToken) {
      localStorage.setItem('auth-token', authToken.access);
      localStorage.setItem('refresh-token', authToken.refresh);
      const dashboard = getRoute('home');
      navigate(dashboard.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('requesting login');
    requestLogin();
  };

  return (
    <Container>
      <Paper
        sx={{
          margin: 'auto',
          marginTop: 10,
          padding: 8,
          maxWidth: 500,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        elevation={3}
      >
        <Box
          component='img'
          sx={{
            height: 200,
            width: 350,
            maxHeight: { xs: 150, md: 120 },
            maxWidth: { xs: 300, md: 250 },
            margin: 2,
          }}
          src={Logo}
        />
        <Typography component='h1' variant='h5'>
          Login
        </Typography>
        <Box sx={{ mt: 1 }} component='form' onSubmit={handleSubmit}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete='current-password'
          />
          <FormControlLabel
            control={<Checkbox value='remember' color='primary' />}
            label='Remember Me'
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Olvidaste tu contraseña?
              </Link>
            </Grid>
          </Grid> */}
        </Box>
      </Paper>
    </Container>
  );
};
