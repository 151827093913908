import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import {
  Autocomplete,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useCreateRestaurant, useGetAllVenues } from '../../hooks';
import { FloatingModal } from '../../atoms/FloatingModal';
import { QueryKey, useQueryClient } from 'react-query';
import { userKeys } from '../../utils/rqKeys';
import { useParams } from 'react-router-dom';
import { ICompanyResponse } from '../../hooks/company/get';
import { LoadingPage } from '../../pages';

interface IProps {
  open: boolean;
  handleOpen: (o: boolean) => void;
}

export const CreateRestaurantModal = ({ open, handleOpen }: IProps) => {
  const [restaurant, setRestaurant] = useState<string>('');
  const [venue, setVenue] = useState<string | null>(null);
  const { slug: companyId = '' } = useParams();
  const queryClient = useQueryClient();
  const qcCompanyData = queryClient.getQueriesData(
    userKeys.company(companyId)
  ) as [QueryKey, ICompanyResponse][];

  const { status, mutate } = useCreateRestaurant({
    options: {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries(userKeys.companyRestaurants(companyId));
      },
    },
    name: restaurant,
    venue,
    company: companyId,
  });

  const { data: venues, isLoading: isLoadingVenues } = useGetAllVenues({});

  useEffect(() => {
    if (status === 'success') {
      setRestaurant('');
      setVenue(null);
      handleOpen(false);
    }
  }, [status, handleOpen]);

  const handleSubmit = () => {
    if (restaurant) {
      mutate();
    }
  };

  return (
    <FloatingModal isOpen={open} handleOpen={() => handleOpen(false)}>
      {isLoadingVenues ? (
        <LoadingPage />
      ) : (
        <>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {`Create new Restaurant for ${
              qcCompanyData[0][1] ? qcCompanyData[0][1].name : ''
            }`}
          </Typography>
          <Divider />
          <TextField
            id='restaurant-name'
            label='Restaurant Name'
            name='restaurant_name'
            variant='standard'
            type={'string'}
            value={restaurant}
            onChange={(e) => setRestaurant(e.target.value)}
            required
          />

          <Autocomplete
            id='venues'
            options={venues?.results || []}
            renderInput={(params) => (
              <TextField {...params} label='Venue' variant='standard' />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.random_slug}>
                  {option.name}
                </li>
              );
            }}
            getOptionLabel={(option) => option.name}
            onChange={(_event, v) => setVenue(v?.random_slug || null)}
          />

          <Grid display={'flex'} flexDirection={'row'} marginTop={2}>
            <Button
              variant='contained'
              color='success'
              sx={{ marginRight: 1 }}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
            <Button
              variant='contained'
              color='error'
              sx={{ marginLeft: 1 }}
              onClick={() => handleOpen(false)}
            >
              Cancel
            </Button>
          </Grid>
        </>
      )}
    </FloatingModal>
  );
};
