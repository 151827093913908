import { useMutation } from 'react-query';
import { getEndpoint } from '../../utils/getEndpoint';
import { request } from '../../utils/axios';
import { IRQRequest } from '../../types';

interface IRQNewVenueRequest extends IRQRequest {
  name: string;
}

interface IVenueResponse {
  random_slug: string;
  identifier: string;
  create_timestamp: string;
  update_timestamp: string;
  name: string;
}

/**
 * Hook to get the list of all the venues the user has access to see
 * @returns {IVenueResponse}
 */
export const useCreateVenue = ({ options = {}, name }: IRQNewVenueRequest) => {
  return useMutation<IVenueResponse, Error>(
    () =>
      request({
        method: 'POST',
        data: {
          name,
        },
        url: getEndpoint('staff/Venue', true),
      }),
    options
  );
};
