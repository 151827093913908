import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface Dishes {
  name: string;
  percent: string;
}

interface Breakdown {
  category: string;
  percent: string;
  dishes: Array<Dishes>;
}

function Row(props: { row: Breakdown }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.category}
        </TableCell>
        <TableCell align='right'>{row.percent}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                Breakdown
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Dish</TableCell>
                    <TableCell>%</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.dishes.map((dish) => (
                    <TableRow key={dish.name}>
                      <TableCell component='th' scope='row'>
                        {dish.name}
                      </TableCell>
                      <TableCell>{dish.percent}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const rows = [
  {
    category: 'Appetizers',
    percent: '5%',
    dishes: [
      { name: 'Salmon Roll', percent: '30%' },
      { name: 'Cheese Sticks', percent: '75%' },
      { name: 'Others', percent: '5%' },
    ],
  },
  {
    category: 'Meals',
    percent: '45%',
    dishes: [
      { name: 'Italian Pasta', percent: '30%' },
      { name: 'Bacon Burger', percent: '23%' },
      { name: 'Peperoni Pizza', percent: '15%' },
      { name: 'Others', percent: '32%' },
    ],
  },
  {
    category: 'Desserts',
    percent: '25%',
    dishes: [
      { name: 'Chocolate IceCream', percent: '40%' },
      { name: 'Vanilla IceCream', percent: '30%' },
      { name: 'Cheesecake', percent: '30%' },
    ],
  },
  {
    category: 'Beverages',
    percent: '25%',
    dishes: [
      { name: 'Coca-Cola', percent: '40%' },
      { name: 'Beer', percent: '30%' },
      { name: 'Water', percent: '15%' },
      { name: '0 Alcohol Beer', percent: '12%' },
      { name: 'Others', percent: '3%' },
    ],
  },
];

export const TopSellingDishes = () => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Category</TableCell>
            <TableCell align='right'>Total %</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.category} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TopSellingDishes;
