import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

interface IProps {
  drawerWidth: number;
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
  drawer: JSX.Element;
}

export const NavigationDrawer = ({
  drawerWidth,
  handleDrawerToggle,
  mobileOpen,
  drawer,
}: IProps) => {
  return (
    <Box
      component='nav'
      sx={{
        width: { md: drawerWidth },
        flexShrink: { sm: 0 },
      }}
      aria-label='mailbox folders'
    >
      <Drawer
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant='permanent'
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          backgroundColor: 'transparent',
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
