import { useQuery } from 'react-query';
import { getEndpoint } from '../../utils/getEndpoint';
import { request } from '../../utils/axios';
import { userKeys } from '../../utils/rqKeys';
import { IRQRequest } from '../../types';

interface IRQLogin extends IRQRequest {
  data: {
    username: string;
    password: string;
  };
}

interface ILoginResponse {
  access: string;
  refresh: string;
}

/**
 * Hook for making a login request using the username and password of the user
 * @param loginData
 * @returns
 */
export const useRequestLogin = ({ options = {}, data }: IRQLogin) => {
  return useQuery<ILoginResponse, Error>(
    userKeys.token(),
    () => request({ method: 'POST', data, url: getEndpoint('token') }),
    options
  );
};
