import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name: string, count: number) {
  return { name, count };
}

function createRows() {
  return [
    createData('Pending', random(1, 5)),
    createData('New', random(2, 6)),
    createData('Preparing', random(5, 10)),
  ];
}

const random = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min) + min);
};

export default function SummaryOrders() {
  const [total, setTotal] = useState<number>(0);
  const [rows, setRows] = useState<Array<any>>(createRows());

  useEffect(() => {
    const timer = setTimeout(() => {
      setRows(createRows());
      setTotal(random(total + 1, total + 5));
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [total]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: '100%' }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell align='right'>No. Orders</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component='th' scope='row'>
                {row.name}
              </TableCell>
              <TableCell align='right'>{row.count}</TableCell>
            </TableRow>
          ))}
          <TableRow key={'Delivered'}>
            <TableCell component='th'>{'Delivered'}</TableCell>
            <TableCell align='right'>{total}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
