import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Divider, Grid, TextField, Typography } from '@mui/material';
import { useCreateCompany } from '../../hooks';
import { FloatingModal } from '../../atoms/FloatingModal';
import { useQueryClient } from 'react-query';
import { userKeys } from '../../utils/rqKeys';

interface IProps {
  open: boolean;
  handleOpen: (o: boolean) => void;
}

export const CreateCompanyModal = ({ open, handleOpen }: IProps) => {
  const [company, setCompany] = useState<string>('');
  const queryClient = useQueryClient();
  const {
    status,
    mutate,
  } = useCreateCompany({
    options: {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries(userKeys.companies);
      },
    },
    name: company,
  });

  useEffect(() => {
    if (status === 'success') {
      setCompany('');
      handleOpen(false);
    }
  }, [status]);

  const handleSubmit = () => {
    if (company) {
      mutate();
    }
  };

  return (
    <FloatingModal isOpen={open} handleOpen={() => handleOpen(false)}>
      <>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          Create new company
        </Typography>
        <Divider />
        <TextField
          id='standard-basic'
          label='Company Name'
          name='company_name'
          variant='standard'
          type={'string'}
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          required
        />
        <Grid display={'flex'} flexDirection={'row'} marginTop={2}>
          <Button
            variant='contained'
            color='success'
            sx={{ marginRight: 1 }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
          <Button
            variant='contained'
            color='error'
            sx={{ marginLeft: 1 }}
            onClick={() => handleOpen(false)}
          >
            Cancel
          </Button>
        </Grid>
      </>
    </FloatingModal>
  );
};
