import { useQuery } from 'react-query';
import { getEndpoint } from '../../utils/getEndpoint';
import { request } from '../../utils/axios';
import { IRQRequest } from '../../types';
import { userKeys } from '../../utils/rqKeys';

interface IRQTokenRefresh extends IRQRequest {
  token: string;
}

interface ITokenRefreshResponse {
  access: string;
  refresh: string;
}

/**
 * Refresh the token data and return the new current token and refresh
 * @param loginData
 * @returns
 */
export const useRequestRefreshToken = ({ token }: IRQTokenRefresh) => {
  return useQuery<ITokenRefreshResponse, Error>(userKeys.token(), () =>
    request({
      method: 'POST',
      data: { refresh: token },
      url: getEndpoint('token/refresh'),
    })
  );
};