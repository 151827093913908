import React from 'react';
import { Link } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';

interface IRow {
  name: string;
  random_slug: string;
  identifier: string;
}

interface IProps {
  data: IRow[];
}

const columns: MUIDataTableColumnDef[] = [
  {
    name: 'name',
    label: 'Company',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Link component={LinkRouter} to={tableMeta.rowData[1]}>
            {value}
          </Link>
        );
      },
    },
  },
  {
    name: 'random_slug',
    label: 'Slug',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'identifier',
    label: 'Identifier',
    options: {
      filter: true,
      sort: false,
    },
  },
];

const Datatable = (props: IProps) => {
  return (
    <MUIDataTable title={'Companies'} data={props.data} columns={columns} />
  );
};

export default Datatable;
