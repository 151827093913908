import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

interface IProps {
  children: JSX.Element;
  handleOpen: () => void;
  isOpen: boolean;
}

export const FloatingModal = (props: IProps) => {
  return (
    <Modal
      open={props.isOpen}
      onClose={props.handleOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          padding: 3,
          borderRadius: 3,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
        display={'flex'}
        flexDirection={'column'}
      >
        {props.children}
      </Box>
    </Modal>
  );
};
