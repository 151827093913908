import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid, Tabs, Tab } from '@mui/material';
import { LoadingPage } from '../';
import { useGetCompany } from '../../hooks/company/get';
import RestaurantsTab from './restaurantsTab';
import { TabPanel } from '../../atoms/TabPanel';
import Revenue from '../../components/Charts/revenue';
import SummaryOrders from './sumaryOrders';
import MenuCreation from '../../components/Menu/menu';
import TopSellingDishes from './topSellingDishes';
import RevenueByRestaurants from '../../components/Charts/revenueByRestaurants';

export const CompanyPage = () => {
  const { slug } = useParams();
  const [tab, setTab] = useState<number>(0);
  const { data, isLoading } = useGetCompany({ slug });
  console.log(data);

  if (isLoading) return <LoadingPage />;

  return (
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography variant='h5' gutterBottom>
        {`Company - ${data?.name}`}
      </Typography>
      <Grid
        sx={{
          marginBottom: 2,
        }}
      >
        <Paper
          sx={{
            elevation: 1,
            marginBottom: 2,
          }}
        >
          <Tabs value={tab} onChange={(event, newTab) => setTab(newTab)}>
            <Tab label='General' />
            <Tab label='Restaurants' />
            <Tab label='Menu' />
            <Tab label='Settings' />
          </Tabs>
        </Paper>
        <TabPanel tab={tab} index={0}>
          <Grid
            sx={{
              flexDirection: 'column',
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                height: '350px',
              }}
            >
              <Paper
                sx={{
                  flex: 2,
                  padding: 2,
                  marginRight: 1,
                  height: '100%',
                  width: '100%',
                }}
              >
                <Typography variant='h6' gutterBottom>
                  {`Revenue`}
                </Typography>
                <Box
                  sx={{
                    height: '100%',
                    paddingBottom: 3,
                  }}
                >
                  <Revenue />
                </Box>
              </Paper>
              <Paper
                sx={{
                  flex: 1,
                  padding: 2,
                  height: '100%',
                  width: '100%',
                }}
              >
                <Typography variant='h6' gutterBottom>
                  {`Total Revenue by Location`}
                </Typography>
                <Box
                  sx={{
                    height: '100%',
                    paddingBottom: 3,
                  }}
                >
                  <RevenueByRestaurants />
                </Box>
              </Paper>
            </Grid>
            <Grid
              sx={{
                marginTop: 1,
                display: 'flex',
                flexDirection: 'row',
                minHeight: '350px',
              }}
            >
              <Paper
                sx={{
                  padding: 2,
                  flex: 1,
                  marginRight: 1,
                  height: '100%',
                  width: '100%',
                }}
              >
                <TopSellingDishes />
              </Paper>
              <Paper
                sx={{
                  flex: 1,
                  padding: 2,
                  marginRight: 0,
                }}
              >
                <Typography variant='h6' gutterBottom>
                  {`Summary Orders Today`}
                </Typography>
                <SummaryOrders />
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel tab={tab} index={1}>
          <RestaurantsTab />
        </TabPanel>
        <TabPanel tab={tab} index={2}>
          <MenuCreation />
        </TabPanel>
      </Grid>
    </Box>
  );
};
