import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const client = axios.create({});

/**
 * Set authentication token to the axios instance
 * @param token
 */
export const setAuthToken = (token: string) => {
  client.interceptors.request.use(function (config) {
    // const token = localStorage.getItem('token');
    if (config?.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });
};

/**
 * Sets the configuration to use on a request
 * @param RequestConfiguration
 * @returns
 */
export const request = ({ ...options }: AxiosRequestConfig) => {
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  axios.defaults.headers.common['Access-Control-Allow-Methods'] =
    'GET, POST, PUT, DELETE, PATCH, OPTIONS';
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  const onSuccess = (response: AxiosResponse) => response.data;
  const onError = (error: Error) => {
    throw error;
  };
  const config = {
    ...options,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth-token') || ''}`,
    },
  };

  return client(config).then(onSuccess).catch(onError);
};
