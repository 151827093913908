import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const data = [
  {
    name: 'January',
    revenue: 4200,
  },
  {
    name: 'February',
    revenue: 3500,
  },
  {
    name: 'March',
    revenue: 4900,
  },
  {
    name: 'April',
    revenue: 4580,
  },
  {
    name: 'May',
    revenue: 5890,  
  },
];

export const TableSwiftRevenue = () => {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Area type='monotone' dataKey='revenue' stroke='#008000' fill='#228B22' />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default TableSwiftRevenue;
